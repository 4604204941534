<template>
     <div class="bg-bluegray-900 text-gray-100 p-3 flex justify-content-between lg:justify-content-center align-items-center flex-wrap">
        <div class="font-bold mr-8">SURVEY APP</div>
    </div>
      <div class="grid">
        <div class="field col-12 lg:col-6 lg:col-offset-3">
            <div class="m-5 surface-card p-4 shadow-2 border-round">
                <div class="text-center mb-5">
                    <img src="/favicon.png" alt="Image" height="50" class="mb-3">
                    <div class="text-900 text-3xl font-medium mb-3"><b>SYARAT DAN KETENTUAN</b></div>
                    <div class="text-900 text-xl font-medium mb-3">Market Test Gilus Harum</div>
                    <hr>
                    <p></p>
                </div>
            </div>
        </div>
      </div>
</template>